import { Controller } from "@hotwired/stimulus";
import Highcharts from "highcharts";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = ["chart"];
  chartInstances = new Map();

  connect() {
    this.fetchAllCharts = debounce(this.fetchAllCharts.bind(this), 500);
    this.fetchAllCharts();
  }

  fetchAllCharts() {
    this.chartTargets.forEach((chartTarget) => {
      const url = chartTarget.dataset.dataurl;
      const chartType = chartTarget.dataset.chartType;

      // Avoid fetching data if it's already rendered
      if (this.chartInstances.has(chartTarget)) return;

      this.fetchChartData(chartTarget, url, chartType);
    });
  }

  async fetchChartData(chartTarget, url, chartType) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch chart data");
      const data = await response.json();

      if (chartType === "by_breakout") {
        this.renderDoughnutChart(chartTarget, data);
      } else if (chartType === "by_protocol") {
        this.renderCallsChart(chartTarget, data);
      } else if (chartType === "by_failures") {
        this.renderFailuresChart(chartTarget, data);
      } else {
        console.error("Unsupported chart type:", chartType);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }

  renderDoughnutChart(chartTarget, data) {
    this.destroyChart(chartTarget);

    const options = {
      chart: {
        type: "pie",
        backgroundColor: "transparent",
        animation: false,
        reflow: false,
      },
      title: { text: data.name || "" },
      plotOptions: {
        pie: {
          innerSize: "30%",
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: { enabled: false },
        },
      },
      series: [
        {
          name: "Result",
          colorByPoint: true,
          data: [
            { name: "Passed", y: data.passed, color: "#200041" },
            { name: "Failed", y: data.failed, color: "#F44708" },
          ],
        },
      ],
    };

    const chart = Highcharts.chart(chartTarget, options);
    this.chartInstances.set(chartTarget, chart);
  }

  renderCallsChart(chartTarget, data) {
    this.destroyChart(chartTarget);

    const options = {
      chart: { type: "line", reflow: false },
      title: { text: chartTarget.dataset.title },
      xAxis: { type: "datetime", title: { text: "Timestamp" } },
      yAxis: { title: { text: "Total Calls" } },
      series: [
        { name: "SIP", color: "#d7c5ea", data: data?.SIP || [] },
        { name: "PSTN", color: "#200041", data: data?.PSTN || [] },
        { name: "IN_COUNTRY", color: "#F44708", data: data?.IN_COUNTRY || [] },
      ],
    };

    const chart = Highcharts.chart(chartTarget, options);
    this.chartInstances.set(chartTarget, chart);
  }

  renderFailuresChart(chartTarget, data) {
    this.destroyChart(chartTarget);

    const series = Object.keys(data || {}).map((key) => ({
      name: key,
      data: data[key],
      color: this.getColorForSeries(key),
    }));

    const options = {
      chart: { type: "bar", reflow: false },
      title: { text: chartTarget.dataset.title },
      xAxis: { type: "datetime", title: { text: "Timestamp" } },
      yAxis: { title: { text: "Total Failures" } },
      series: series,
    };

    const chart = Highcharts.chart(chartTarget, options);
    this.chartInstances.set(chartTarget, chart);
  }

  getColorForSeries(key) {
    const baseColors = ["#d7c5ea", "#200041", "#F44708"];
    const index = Object.keys(this.chartInstances).length % baseColors.length;
    return baseColors[index];
  }

  destroyChart(chartTarget) {
    const existingChart = this.chartInstances.get(chartTarget);
    if (existingChart) {
      existingChart.destroy();
      this.chartInstances.delete(chartTarget);
    }
  }

  disconnect() {
    this.chartInstances.forEach((chart) => chart.destroy());
    this.chartInstances.clear();
  }
}
