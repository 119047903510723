import Flashphoner from '@flashphoner/websdk'


$(document).on('turbo:load', function() {
  if ($('#sipPhone').is(':visible')) {    
    $('#submitCall').click(function () {      
      var destination_number = $('#test_case_destinations').val();

      // Check if destination number is empty
      if ($.trim(destination_number) === '' ) {                       
        alert("Destination number required.");        
      } else {        
        document.querySelector("#sip_call_form").requestSubmit()
        $('#callStatus').removeClass('hidden').addClass("text-font-600").addClass("animate-pulse")
        $('#callStatus').text("TEST STARTED");

        $('#sip_call_form :input').prop('disabled', true);
        $('#currentlyMuted').prop('disabled', false);  

        // setTimeout(refreshLiveTests, 5000);
      }

    })

    function refreshLiveTests() {
      let url = $('#sipPhone').data("get-update-url")
      $.get(`${url}?${(new Date).getTime()}`);
    };

    $('.digit').click(function (e) {
      $('#test_case_destinations').val(($("#test_case_destinations").val() + e.target.innerHTML).trim())
      sendDTMF(e.target.innerHTML)
    })

    $('#test_case_destinations').keyup( function () {
      $('#test_case_destinations').val(($("#test_case_destinations").val().replace(/\s/g,"")))
    })

    $('#backspace').click(function () {
      $('#test_case_destinations').val($("#test_case_destinations").val().slice(0, -1))
    })

    $("#muteAudioToggle").click(function () {            
        // $('#currentlyMuted').prop('checked', !$('#currentlyMuted').prop('checked'));
        if ($('#currentlyMuted').prop('checked')) {        
          mute();          
        } else { 
          unmute();         
        }
    });

    try {
      Flashphoner.init();
    } catch(e) {
        $("#notifyFlash").text("Your browser doesn't support WebRTC technology needed for this example");
        return;
    }

    var SESSION_STATUS = Flashphoner.constants.SESSION_STATUS; 
    var CALL_STATUS = Flashphoner.constants.CALL_STATUS;
    var PRELOADER_URL = "../../dependencies/media/preloader.mp4";
    var Browser = Flashphoner.Browser;
    var localDisplay;
    var remoteDisplay;
    var currentCall;

    showOutgoing();
	
    onHangupOutgoing();
    onDisconnected();

        
    localDisplay = document.getElementById("localDisplay");
    remoteDisplay = document.getElementById("remoteDisplay");


  //   $("#holdBtn").click(function(){
  //     var state = $(this).text();
  //     if (state == "Hold") {
  //         $(this).text("Unhold");
  //         currentCall.hold();
  //     } else {
  //         $(this).text("Hold");
  //         currentCall.unhold();
  //     }
  //     $(this).prop('disabled', true);
  // });


function connect(authToken) {
  if (Browser.isSafariWebRTC() && Flashphoner.getMediaProviders()[0] === "WebRTC") {
          Flashphoner.playFirstVideo(localDisplay, true, PRELOADER_URL).then(function () {
              Flashphoner.playFirstVideo(remoteDisplay, false, PRELOADER_URL).then(function () {
                  createSession(authToken);
              });
          });
          return;
  }
  createSession(authToken);
}

function createSession(authToken) {
  var turn_server = $('#urlServer').data('turn')
  var url = $('#urlServer').val();

  var registerRequired = $("#sipRegisterRequired").is(':checked');
  var sipOptions = {
      login: $("#sipLogin").val(),
      authenticationName: $("#sipAuthenticationName").val(),
      password: $("#sipPassword").val(),
      domain: $("#sipDomain").val(),
      outboundProxy: $("#sipOutboundProxy").val(),
      port: $("#sipPort").val(),
      registerRequired: registerRequired
  };

  if ($('#urlServer').data('turn')) {
    var connectionOptions = {
      urlServer: url,
      keepAlive: true
  };

  } else {
    var connectionOptions = {
      urlServer: url,
      keepAlive: true
  };

  }

  if (authToken) {
      connectionOptions.authToken = authToken;
  } else {
      connectionOptions.sipOptions = sipOptions;
  }

  //create session
  console.log("Create new session with url " + url);
  Flashphoner.createSession(connectionOptions).on(SESSION_STATUS.ESTABLISHED, function(session, connection){
      setStatus("#regStatus", SESSION_STATUS.ESTABLISHED);
      $("#authToken").val(connection.authToken);
      onConnected(session);
      if (!registerRequired) {
          disableOutgoing(false);
      }
  }).on(SESSION_STATUS.REGISTERED, function(session){      
      setStatus("#regStatus", SESSION_STATUS.REGISTERED);
      onConnected(session);
      if (registerRequired) {
          disableOutgoing(false);
      }
  }).on(SESSION_STATUS.DISCONNECTED, function(){      
      setStatus("#regStatus", SESSION_STATUS.DISCONNECTED);
      onDisconnected();
  }).on(SESSION_STATUS.FAILED, function(){      
      setStatus("#regStatus", SESSION_STATUS.FAILED);
      onDisconnected();
  }).on(SESSION_STATUS.INCOMING_CALL, function(call){      
      call.on(CALL_STATUS.RING, function(){          
          setStatus("#callStatus", CALL_STATUS.RING);
      }).on(CALL_STATUS.ESTABLISHED, function(){          
          setStatus("#callStatus", CALL_STATUS.ESTABLISHED);
          // $("#holdBtn").prop('disabled',false);
      }).on(CALL_STATUS.HOLD, function() {          
          // $("#holdBtn").prop('disabled',false);
      }).on(CALL_STATUS.FINISH, function(){
          setStatus("#callStatus", CALL_STATUS.FINISH);
          console.log("Load page again - session");
          location.reload();

          // onHangupIncoming();          
          currentCall = null;
      }).on(CALL_STATUS.FAILED, function(){          
          setStatus("#callStatus", CALL_STATUS.FAILED);
          onHangupIncoming();
          currentCall = null;
      });
      onIncomingCall(call);
  });
}

function call() {  
  var session = Flashphoner.getSessions()[0];

  var constraints = {
        audio: true,
        video: false
    };  

  //prepare outgoing call 
    var outCall = session.createCall({
    callee: $("#callee").val(),
    visibleName: $("#sipLogin").val(),
    localVideoDisplay: localDisplay,
    remoteVideoDisplay: remoteDisplay,
    constraints: constraints,
    receiveAudio: true,
        receiveVideo: false,
        stripCodecs:"SILK"
  }).on(CALL_STATUS.RING, function(){
    setStatus("#callStatus", CALL_STATUS.RING);
    }).on(CALL_STATUS.ESTABLISHED, function(){
    setStatus("#callStatus", CALL_STATUS.ESTABLISHED);
        // $("#holdBtn").prop('disabled',false);
        onAnswerOutgoing();
    }).on(CALL_STATUS.HOLD, function() {
        // $("#holdBtn").prop('disabled',false);
    }).on(CALL_STATUS.FINISH, function(){
    setStatus("#callStatus", CALL_STATUS.FINISH);
      console.log("Load page again - call");           
      location.reload();
      //onHangupOutgoing();
    currentCall = null;
    }).on(CALL_STATUS.FAILED, function(){
        setStatus("#callStatus", CALL_STATUS.FAILED);
        onHangupIncoming();
        currentCall = null;
    });

  outCall.call();
  currentCall = outCall;

    $("#callBtn").addClass("alert-text").off('click').click(function(){
          $(this).prop('disabled', true);
      outCall.hangup();
    }).prop('disabled', false);  

    // Disable the submit call button during a call
    $("#submitCall").addClass("alert-text").off('click').click(function(){
          $(this).prop('disabled', true);          
      outCall.hangup();
    }).prop('disabled', false);
  }

function onConnected(session) {
    $("#connectBtn").text("Disconnect").off('click').click(function(){
        $(this).prop('disabled', true);
    if (currentCall) {
      showOutgoing();
      disableOutgoing(true);
      setStatus("#callStatus", "");
      currentCall.hangup();
    }
        session.disconnect();
    }).prop('disabled', false);
}

function onDisconnected() {
  connect();
  disableOutgoing(true);
  showOutgoing();
  setStatus("#callStatus", "");
}

function onHangupOutgoing() {
  $('#sip_call_form :input').prop('disabled', false);
  if ($('#test_case_protocol').val() === 'PSTN') {
    $('#country_selector').prop('disabled', true);
    $('#test_case_breakout_id').prop('disabled', true);
  } else {
    $('#test_case_source_ddi').prop('disabled', true);
  }
  $("#callBtn").removeClass('text-red-500').off('click').click(function(){
  if (filledInput($("#callee"))) {
    disableOutgoing(true);
    call();
  }
  }).prop('disabled', false);
  $('#callee').prop('disabled', false);
  $("#callFeatures").addClass('hidden');
  // $("#holdBtn").text("Hold");
  disableOutgoing(false);
}

function onIncomingCall(inCall) {
  currentCall = inCall;

  showIncoming(inCall.caller());
  $("#answerBtn").prop('disabled', true);
  $("#sipPhone").addClass('border-green-400');
  $("#sipPhone").addClass('shadow-green-400');
  $("#sipPhone .infobar").addClass('bg-green-400');

  $(".alertbar").attr('style','background-color: #458B00');
  var constraints = {
            audio: true,
            video: false
        };
  inCall.answer({
            localVideoDisplay: localDisplay,
            remoteVideoDisplay: remoteDisplay,
            receiveVideo: false,
            constraints: constraints,
            stripCodecs:"SILK"
        });
  showAnswered();


    $("#hangupBtn").off('click').click(function(){
    $(this).prop('disabled', true);
    $("#answerBtn").prop('disabled', true);
    $(".keypad").attr('style','border-color: #dddddd');
    inCall.hangup();
    }).prop('disabled', false);
}

function onHangupIncoming() {
  showOutgoing();
}

function onAnswerOutgoing() {
  $("#callFeatures").removeClass('hidden');
}

// Set connection and call status
function setStatus(selector, status) {
  console.log(status)
  var statusField = $(selector);
  statusField.text(status).removeClass();
  if (status == "REGISTERED" || status == "ESTABLISHED") {
      statusField.attr("class","text-green-500");
  } else if (status == "DISCONNECTED" || status == "FINISHED") {
      statusField.attr("class","text-muted");
  } else if (status == "FAILED") {
      statusField.attr("class","text-red-500");
  } else if (status == "TRYING" || status == "RING") {
      statusField.attr("class","text-alert-500");
  }
}

// Display view for incoming call
function showIncoming(callerName){
  $("#outgoingCall").addClass('hidden');
  $("#incomingCall").removeClass('hidden');
  $("#callStatus").removeClass('hidden').text("Incoming Call " + callerName);
  $("#answerBtn").removeClass('hidden');
  // Show Hangup Button
  $('#hangupBtn').removeClass('hidden');
  // Disable submit call button
  $('#submitCall').css('pointer-events', 'none');
}

// Display view for outgoing call
function showOutgoing(){
  $("#incomingCall").addClass('hidden');
  $("#hangupBtn").addClass('hidden');
  $("#answerBtn").addClass('hidden');
  $("#sipPhone").removeClass('border-green-400');
  $("#sipPhone").removeClass('shadow-green-400');
  $("#sipPhone .infobar").removeClass('bg-green-400');
  $("#callStatus").addClass('hidden');
  $("#outgoingCall").removeClass('hidden');
  $("#callFeatures").addClass('hidden');
  onHangupOutgoing();
}

function disableOutgoing(disable) {
  $('#callee').prop('disabled', disable);
  $("#callBtn").prop('disabled', disable);
  $("#submitCall").prop('disabled', disable);  
}

// Display view for answered call
function showAnswered(){
  $("#answerBtn").addClass('hidden');
  $("#hangupBtn").removeClass('hidden');
  $("#callFeatures").removeClass('hidden'); 
  $("#callStatus").addClass('hidden').text("");
}

// Mute audio in the call
function mute() {
  if (currentCall) {    
    $('#muteAudioToggle').addClass('text-alert-500');
    $('#muteAudioToggle').removeClass('text-font-600');
    $('#currentlyMuted').prop("checked", false);
    currentCall.muteAudio();
  } else {    
    $('#muteAudioToggle').removeClass('text-red-500')    
    $('#muteAudioToggle').addClass('text-font-600');
    $('#currentlyMuted').prop("checked", false);
  }
}

// Unmute audio in the call
function unmute() {
  if (currentCall) {
    $('#muteAudioToggle').removeClass('text-alert-500')
    $('#muteAudioToggle').addClass('text-font-600');
    $('#currentlyMuted').prop("checked", true);
    currentCall.unmuteAudio();
  } else {    
    $('#muteAudioToggle').removeClass('text-alert-500')
    $('#muteAudioToggle').addClass('text-font-600');
    $('#currentlyMuted').prop("checked", true);
  }
}


function sendDTMF(dtmf) {
  if (currentCall) {
    currentCall.sendDTMF(dtmf);
  }
}
}
});
