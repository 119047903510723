import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
// Connects to data-controller="dynamic-dropdown"
export default class extends Controller {
  static targets = ["select", "param"];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    if (this.hasSelectTarget && this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36);
    }
    if (this.hasSelectTarget && this.selectTarget.id == "") {
      this.change();
    }
  }

  change() {
    let params = new URLSearchParams();
    if (this.hasSelectTarget) {
      params.append("target", this.selectTarget.id);
    }
    if (this.hasParamTarget) {
      this.paramTargets.forEach((element) => {
        if (element.selectedOptions[0].value.length > 0) {
          params.append(
            element.dataset.paramName,
            element.selectedOptions[0].value,
          );
        }
      });
    }

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
