// Entry point for the build script in your package.json

import "./src/jquery";
import "./src/js-loader";

import "multiselect/css/multi-select.css";
import "flatpickr/dist/flatpickr.min.css";
import "tom-select/dist/css/tom-select.min.css";
import "jstree/dist/themes/default/style.min.css";
import "trumbowyg/dist/ui/trumbowyg.min.css";
import "trumbowyg";

import "./src/audio_options";
import "./src/bulk_csv_field";
import "./src/cxplore";
import "./src/dashboard";
import "./src/flashphone";
import "./src/global";
import "./src/help_guide";
import "./src/init";
import "./src/integrations";
import "./src/toggle_row";
import "./src/modal";
import "./src/table_view";
import "./src/flowchart";
import "./src/selector_init";
import "./src/search_box";
import "./src/arrow";
import "./src/test_cases";
import "./src/test_slack";
import "./src/flowchart";
import "./src/selector_init";
import "./src/click_to_copy";
import "./src/recurring-select";
import "./src/agent_view";
import "./src/campaign";

import "./old_src/docs";
//import "./old_src/ivr-mappers"
import "./old_src/ivr-scripts";
import "./old_src/ivr-steps";
import "./old_src/outbound-tests";
import "./old_src/registration-form";
import "./old_src/test_case_ivr_steps";
import "./old_src/test-additional";
import "./old_src/test-calls";
import "./old_src/test-cases";

import "./channels";
import "./controllers";
import "../components"; // Include view component stimulus files
import "@hotwired/turbo-rails";
import "@rails/actiontext";
