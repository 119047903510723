import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utilities"
export default class extends Controller {
  static targets = ['link', 'loader', 'targetDiv', 'dateTimePicker', 'checkbox']
  

  connect() {
    console.log("utilities active")
  }

  ask(event) {
    if (!confirm("This will reset the whole layout?")) {
      event.preventDefault()
    }
  }

  toggleLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.toggle('hidden')
    }
  }

  toggleDiv(e) {
    // Adding a flag to allow default behaviour when toggling target element
    if (e.target.dataset.utilitiesPreventDefaultValue !== 'false') {
      e.preventDefault();
    }

    if (this.hasTargetDivTarget) {
      this.targetDivTarget.classList.toggle('hidden')
    }
  }

  toggleHidden(event) {
    event.preventDefault()
    const index = event.currentTarget.dataset.index;
    const arrow = event.currentTarget.querySelector('.toggle_arrow');

    if (arrow) {
      arrow.classList.toggle("rotate-90");
    }

    const element = this.targetDivTargets.find((el) => el.dataset.index === index);
    console.log(element)
    element.classList.toggle('hidden');
  }

  reloadWithFilters(event) {
    let url 
    const param = event.target.dataset.filter  
    const turboFrame = event.target.dataset.utilitiesTargetFrame
    if (event.target.value) {
       url = `${window.location.pathname}?${param}=${event.target.value}`
    } else {
      url = `${window.location.pathname}`
    }

    Turbo.cache.clear()
    Turbo.visit(url, { frame: turboFrame })
  }

  toggleDateTimePicker() {
    if (this.checkboxTarget.checked) {
      this.dateTimePickerTarget.classList.remove('hidden');
      this.initializeDateTimePicker();
    } else {
      this.dateTimePickerTarget.classList.add('hidden');
    }
  }

  initializeDateTimePicker() {
    if (this.dateTimePickerTarget) {
      $(this.dateTimePickerTarget).find('.datetimepicker').flatpickr({
        dateFormat: "d-m-Y H:i",
        defaultDate: new Date(),
        disableDblClickSelection: true,
        enableTime: true,
        closeButton: true,
        autoclose: true,
        locale: {
          firstDayOfWeek: 1
        }
      });
    }
  }

}