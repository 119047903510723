import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="webhooks"
export default class extends Controller {
  static targets = [
    "result",
    "url",
    "user",
    "password",
    "message",
    "apiHeader",
    "apiHeaderValue",
  ];
  connect() {}

  async fireTest() {
    const data = {
      url: this.urlTarget.value,
      user_name: this.userTarget.value,
      password: this.passwordTarget.value,
      message: this.messageTarget.value,
      api_header: this.apiHeaderTarget.value,
      api_header_value: this.apiHeaderValueTarget.value,
    };
    const res = await fetch(this.resultTarget.dataset.posturl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(data),
    });
    const final = await res.json();
    this.resultTarget.innerHTML = final.message;
  }
}
