import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dashboard-form"
export default class extends Controller {
  static targets = ["window", "starttime", "endtime"];

  connect() {}

  changeTimeWindow(e) {
    // add value of timeSelectTarget as time_window query parameter
    const timeWindow = this.windowTarget.value;
    const startTime = this.starttimeTarget.value;
    const endTime = this.endtimeTarget.value;
    const url = new URL(window.location.href);
    url.search = "";
    if (e.target.dataset.window) {
      url.searchParams.set("window", 1);
      url.searchParams.set("time_window", timeWindow);
    } else {
      url.searchParams.set("time_window", "");
    }

    url.searchParams.set("starttime", startTime);
    url.searchParams.set("endtime", endTime);
    window.location.href = url.toString();
  }
}
