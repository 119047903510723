import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="api-explorer"
export default class extends Controller {
  static targets = [
    "commandFrame",
    "exampleCode",
    "commandDetails",
    "submitButton",
    "bodyInput",
    "responseContent",
    "response",
  ];

  showCommand(event) {
    event.preventDefault(); // Prevent the default link behavior
    this.commandFrameTarget.innerHTML = "<p>Loading...</p>";
    const url = event.target.dataset.url;
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html",
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok (${response.status})`);
        }
        return response.text();
      })
      .then((html) => {
        this.commandFrameTarget.innerHTML = html;
        this.updateExampleCode(event);
      })
      .catch((error) => {
        console.error("Error loading partial:", error);
        this.commandFrameTarget.innerHTML = "<p>Failed to load content.</p>";
      });
  }

  sendRequest(event) {
    event.preventDefault();
    const details = this.commandDetailsTarget.dataset;
    let endpoint = details.endpoint;

    // Build the final URL with query parameters
    var url = this.replaceUrlParams(endpoint);
    var query_params = this.gatherQueryParams();
    url = `${url}?${query_params.toString()}`;

    let req_body = {
      api_explorer: {
        url: url,
        method: details.method,
        body: "",
      },
    };

    if (this.hasBodyInputTarget) {
      req_body.body = this.bodyInputTarget.value;
    }

    fetch("/api_explorers/run", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
      body: JSON.stringify(req_body),
    })
      .then((response) => response.json())
      .then((data) => {
        this.responseContentTarget.innerHTML = JSON.stringify(data, null, 2);
        this.responseTarget.classList.remove("hidden");
      })
      .catch((error) => {
        this.responseContentTarget.innerHTML = JSON.stringify(error, null, 2);
        this.responseTarget.classList.remove("hidden");
      });
  }

  gatherQueryParams() {
    // Get all inputs with `data-type="variable"`
    const query_inputs = document.querySelectorAll('[data-type="variable"]');

    // Create query parameters from inputs
    const query_params = new URLSearchParams();
    query_inputs.forEach((input) => {
      const key = input.dataset.variable; // Get the variable name
      const value = input.value; // Get input value
      if (key && value) {
        query_params.append(key, value);
      }
    });
    return query_params;
  }

  replaceUrlParams(endpointUrl) {
    // Select all inputs with `data-type="param"`
    const urlParamsInputs = document.querySelectorAll('[data-type="param"]');
    let updatedUrl = endpointUrl;
    // Iterate over all matching inputs
    urlParamsInputs.forEach((input) => {
      const key = input.dataset.param; // Get the variable name
      const value = encodeURIComponent(input.value); // Get input value, URL encode it

      if (key && value) {
        // Replace the placeholder in the URL
        updatedUrl = updatedUrl.replace(`:${key}`, value);
      }
    });

    return updatedUrl;
  }

  updateExampleCode(event) {
    this.exampleCodeTarget.innerHTML = this.formatExample(event);
    this.submitButtonTarget.classList.remove("hidden");
  }

  formatExample(event) {
    const details = this.commandDetailsTarget.dataset;
    let endpoint = details.endpoint;
    var url = this.replaceUrlParams(endpoint);
    var query_params = this.gatherQueryParams();
    url = `${url}?${query_params.toString()}`;

    if ((details.method = "GET")) {
      return `
        fetch('${url}', {
            method: ${details.method},
            headers: {
                'Content-Type': 'application/json',
                'Authorization: Bearer [YOUR API_KEY]'
                'Region: ${details.region}'
            },
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `;
    } else if ((details.method = "POST" || details.method == "PUT")) {
      return `
        fetch('${url}', {
            method: ${details.method},
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(${details.body})
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      `;
    }
  }
}
